import { log } from '_console';
import { makeUseI18n } from '_i18n';

import { useLocale } from './locale';

const getLocalized = async (locale: string, file: string) => {

    // loading the file in dictionary
    log('[i18n] Loading file:', `/${file}/i18n/${locale}.js`);

    const fileData = await import(`../../${file}/i18n/${locale}.js`);

    return fileData.default;
};

export const useI18n = makeUseI18n(useLocale, getLocalized);

