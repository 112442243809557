import { useCallback, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type TypeUseToggleReturn = [
    boolean,
    () => void,
    () => void,
    () => void,
    Dispatch<SetStateAction<boolean>>
]
export const useToggle = (initValue = false): TypeUseToggleReturn => {
    const [val, set] = useState<boolean>(initValue);

    const toggle = useCallback(() => set(!val), [val]);
    const setTrue = useCallback(() => set(true), []);
    const setFalse = useCallback(() => set(false), []);

    return [val, setTrue, setFalse, toggle, set];
};
