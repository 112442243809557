import { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { getVersion } from '_config';

import { getEnv } from '../global/config/env';
import { useI18n } from '../global/hooks/i18n';
import routes from '../global/config/routes';

import cn from './footer.module.scss';

const Footer = () => {

    const version = getVersion();
    const { legals } = getEnv();

    const t = useI18n('footer');

    return <footer id="footer" className={cn.container}>
        <div className={cn.codedBy}>
            {version} • {t('codedBy')}
        </div>
        <div className={cn.links}>
            {legals?.items?.map(item => {
                return <NavLink key={item} to={{
                    pathname: routes[item as keyof typeof routes],
                    search: window.location.search
                }} className={cn.link}>{t(item)}</NavLink>;
            })}
        </div>
    </footer>;
};

export default memo(Footer);
