import { memo } from 'react';
import { Typography } from '@mui/material';
import { cx } from '@emotion/css';

import { EstimatedTime, Heading, ProgressBar, SimpleMarkdown } from '_ui';

import { useI18n } from '../global/hooks/i18n';
import { useLocale } from '../global/hooks/locale';

import Button from './Button';

import cn from './card.module.scss';

type CardProps = {
    ctaLabel: string;
    goToTraining: () => void | Promise<void>;
    isAvailable: boolean;
    loading: boolean;
    name: string;
    trainingType: string;
    track: string;
    headTag?: React.ReactNode;
    estimatedTime?: number;
    startsOn?: Date;
    progression?: number;
}

const Card = ({ ctaLabel, goToTraining, isAvailable, loading, name, trainingType, track, estimatedTime, startsOn, progression }: CardProps) => {

    const t = useI18n('card');
    const locale = useLocale();

    return <article className={cn.card}>
        <header>
            <Typography className={cn.type}>{t(`trainingTypes.${trainingType}`)}</Typography>
        </header>
        <div className={cn.content}>
            <div className={cn.tracknTime}>
                <Typography className={cn.track}>{track}</Typography>
                {estimatedTime && <EstimatedTime estimatedTime={estimatedTime} className={cn.track} />}
            </div>
            <div className={cn.chips}>
                {startsOn && <div className={cx(cn.chip, cn.unavailable)}>
                    <SimpleMarkdown value={t('startsOn', { date: startsOn.toLocaleDateString(locale) })} />
                </div>}
            </div>
            <Heading heading="h4" className={cx(cn.name, startsOn && cn.clampThree)}>{name}</Heading>
        </div>
        <footer>
            {Number.isInteger(progression) && <ProgressBar progress={progression} className={cn.progress} />}
            <Button disabled={!isAvailable} onClick={goToTraining} label={t(`startTraining.${ctaLabel}`)} className={cn.cardButton} loading={loading} />
        </footer>
    </article>;
};

export default memo(Card);
