import { lazy, memo, Suspense } from 'react';
import { useIsMobile } from '_ui';

const AppBar = lazy(() => import('./AppBar'));
const AppBarMobile = lazy(() => import('./AppBarMobile'));

const AppBarWrapper = () => {

    const isMobile = useIsMobile();

    return (
        <Suspense>
            {isMobile ? <AppBarMobile /> : <AppBar />}
        </Suspense>
    );

};

export default memo(AppBarWrapper);
