import { useCallback, useMemo } from 'react';

import { getTranslation, getTranslatable } from '_i18n';

import type { BootcampEnrollment } from '../../enrollments/types';
import { getEnv } from '../../global/config/env';
import { useLocale } from '../../global/hooks/locale';
import { useSwitchEnrollment } from '../../enrollments/hook';
import { useSignedInUser } from '../../global/hooks/user';

export const useBootcampCardInfos = (enrollment: BootcampEnrollment) => {
    const { odysseyUrl } = getEnv();
    const locale = useLocale();
    const { user } = useSignedInUser();
    const { isLoading: loadingSwitchEnrollment, mutateAsync: switchEnrollment } = useSwitchEnrollment();

    const goToTraining = useCallback(async () => {
        // need to switch user current enrollment for odyssey if not the current enrollment
        if (!enrollment.isCurrent) {
            await switchEnrollment(enrollment.id);
        }
        window.location.href = odysseyUrl;
    }, [enrollment, odysseyUrl, switchEnrollment]);

    // disable access to avoid switching user to another bootcamp when masquerading
    const isAvailable = !user?.isMasquerading || enrollment.isCurrent;

    const name = useMemo(() => getTranslation(enrollment.name, locale), [enrollment, locale]);

    return {
        ctaLabel: enrollment.type,
        goToTraining,
        isAvailable,
        name,
        loading: loadingSwitchEnrollment,
        track: getTranslatable(enrollment.track, locale),
        trainingType: enrollment.trainingType
    };
};
