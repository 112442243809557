import { apiErrorHandler, useHttpGet } from '../global/hooks/api';
import { getTrainingWithCertificates } from './endpoint';
import { useTokenApiEndpointWithParams } from '../global/hooks/token';

export const useTrainingWithCertificates = () => {
    const req = useHttpGet(getTrainingWithCertificates, {
        staleTime: Infinity,
        onError: apiErrorHandler
    });
    const { data, ...rest } = req;
    return { ...rest, trainings: data };
};

type DownloadCertificateUrlParamsType = {
    teamId: string;
    locale: string;
    type: string;
    enrollmentId: string;
    isAsynchronous: boolean;
}
export const useDownloadCertificateUrl = ({ teamId, locale, type, enrollmentId, isAsynchronous }: DownloadCertificateUrlParamsType) => {
    /* eslint-disable camelcase */
    const params = new URLSearchParams({
        locale,
        type,
        enrollment_id: enrollmentId,
        teamId,
        ...(isAsynchronous && { is_asynchronous: `${isAsynchronous}` })
    });
    /* eslint-enable */
    const url = useTokenApiEndpointWithParams('api/v3/documents/export', params);

    return url;
};
