import { get as _get } from 'lodash-es';
import { produce } from 'immer';

import { getEnv } from '_config';

type LocalizeValue = string | LocalizeObject;
export interface LocalizeObject {
    [key: string]: LocalizeValue;
}

interface Dictionary {
    [key: string]: LocalizeObject;
}

type DataValue = string | number | undefined;

export interface Data {
    [key: string]: DataValue;
    count?: number;
}

export type DictionaryGetter = (locale: string, file: string) => Promise<LocalizeObject>;

export const getUsableLocale = (locale: string) => {
    const { availableLocales, defaultLocale } = getEnv();
    if (!locale) return defaultLocale;
    if (!availableLocales.includes(locale)) return defaultLocale;
    return locale;
};

export const getDefaultLanguage = () => {
    const { defaultLocale } = getEnv();
    if (typeof window === 'undefined') return defaultLocale;

    // Check if locale is defined in URL
    const searchParams = new URLSearchParams(window.location.search);
    const localeFromParams = searchParams.get('locale');

    if (localeFromParams) return getUsableLocale(localeFromParams);

    // Get locale from navigator
    const localeFromNavigator = navigator.language;
    return getUsableLocale(localeFromNavigator);
};

let DICTIONARY: Dictionary = {};

export const getDictionary = (importDictionary: DictionaryGetter) => async (locale: string, file: string) => {

    const useLocale = getUsableLocale(locale);
    if (DICTIONARY[useLocale]?.[file]) return DICTIONARY[useLocale][file];

    const data = await importDictionary(useLocale, file);

    DICTIONARY = produce(DICTIONARY, state => {
        if (!state[useLocale]) state[useLocale] = {};
        state[useLocale][file] = data;
    });

    return DICTIONARY[useLocale][file];

};

const replaceTranslationVars = (str: string, entries: [string, DataValue][]) => entries.reduce((p, [k, value]) => p.replaceAll(`{{${k}}}`, value as string), str);

export const getTranslation = (dict: LocalizeObject, path: string, data: Data = {}): string => {
    const entries = Object.entries(data);

    // No data, so no vars to replace, nor special arguments, we can directly return the value
    if (entries.length === 0) return _get(dict, path) as string;

    const getTranslationWithInjectedVars = (t: LocalizeValue) => {
        if (!t) return '';
        if (typeof t === 'string') return replaceTranslationVars(t, entries);

        // Translation is an object, we'll map thru each keys to replace vars among keys
        return Object.entries(t).reduce((p, c) => Object.assign(p, {
            [c[0]]: replaceTranslationVars(c[1] as string, entries),
        }), {}) as string;
    };

    // We have a "count" value, so we return a _plural value (if exist)
    if (data.count) {
        // Plural form exists, and count > 1
        const pluralForm = _get(dict, `${path}_plural`);
        if (data.count > 1 && pluralForm) return getTranslationWithInjectedVars(pluralForm);
    }

    // We could add here specificities for gender, or plural form, but no use for now
    return getTranslationWithInjectedVars(_get(dict, path));
};
