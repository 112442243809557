import { Chip, Heading } from '_ui';
import { memo, useCallback } from 'react';
import { getTranslation } from '_i18n';
import { cx } from '@emotion/css';
import { Link } from 'react-router-dom';
import { Alert, LinearProgress } from '@mui/material';

import { AVAILABLE_CERTIFICATES } from './constant';

import { getEnv } from '../global/config/env';
import { useI18n } from '../global/hooks/i18n';
import { useLocale } from '../global/hooks/locale';
import { useTrainingWithCertificates, useDownloadCertificateUrl } from './hook';
import { useTrackEvent } from '../tracking/hooks';

import cn from './certificates.module.scss';

import en from '../assets/countryFlags/gb.png';
import fr from '../assets/countryFlags/fr.png';
import de from '../assets/countryFlags/de.png';
import emptyState from '../assets/illustrations/empty_state_certificate.png';

import type { TrainingWithCertificates } from './types';

const IMAGE_SRCS: {
    [key: string]: string;
    fr: string;
    en: string;
    de: string;
} = { fr, en, de };

const Certificates = () => {
    const t = useI18n('certificates');

    const { isLoading, trainings } = useTrainingWithCertificates();
    const cannotAccessToExitCertificate = (trainings || []).some(training => {
        return training.certificates.some(c => c.type === AVAILABLE_CERTIFICATES.EXIT.type && !c.isAvailable);
    });

    if (isLoading) return <LinearProgress color="primary" />;

    return (
        <div>
            <Heading heading="h2">{t('title')}</Heading>
            <div>
                <Heading heading="h4">{t('explanation')}</Heading>
                {cannotAccessToExitCertificate && <Alert severity="info" className={cn.alert}>{t('alertContent')}</Alert>}
            </div>
            <StateDispatcher trainings={trainings} />
        </div>
    );
};

type DispatcherPropsType = {
    trainings: TrainingWithCertificates[] | undefined;
}
const StateDispatcher = memo(({ trainings }: DispatcherPropsType) => {
    const t = useI18n('certificates.emptyState');

    if (trainings?.length === 0) return (
        <div className={cn.emptyStateContainer}>
            <img src={emptyState} alt="No certificate" />
            <p>{t('content')}</p>
        </div>
    );
    return (
        <div className={cn.programsContainer}>
            {trainings && trainings.map((t: TrainingWithCertificates) => <TrainingContainer key={t.uniqueId} {...t} />)}
        </div>
    );

});

const TrainingContainer = memo(({ enrollmentId, startsOn, endsOn, name, teamId, isAsynchronous, certificates }: TrainingWithCertificates) => {

    const t = useI18n('certificates');

    const locale = useLocale();
    const _name = (() => {
        // Async name are not translated
        if (typeof name === 'string') return name;
        return getTranslation(name, locale);
    })();

    return (
        <div className={cn.programContainer}>
            <div className={cn.programMetadata}>
                <Heading heading="h6" className={cn.programName}>{_name}</Heading>
                <p className={cn.programDates}>{t('trainingDate', { startsOn: startsOn.toLocaleDateString(locale), endsOn: endsOn.toLocaleDateString(locale) })}</p>
            </div>
            <div className={cn.cardsContainer}>
                {certificates.map(c => (
                    <AttestionCard
                        key={`${c.type}-${enrollmentId}`}
                        enrollmentId={enrollmentId}
                        teamId={teamId}
                        type={c.type}
                        isAvailable={c.isAvailable}
                        isAsynchronous={isAsynchronous}
                    />
                ))}
            </div>
        </div>
    );
});

type AttestationCardProps = {
    type: string;
    teamId: string;
    enrollmentId: string;
    isAvailable: boolean;
    isAsynchronous: boolean;
}
const AttestionCard = memo(({ type, isAvailable, ...props }: AttestationCardProps) => {
    const t = useI18n('certificates');
    const env = getEnv();

    const certificate = Object.values(AVAILABLE_CERTIFICATES).find(c => c.type === type);

    if (!certificate) return null;

    return (
        <div className={cx(cn.attestationCardContainer, !isAvailable && cn.inactive)} >
            <img src={certificate.illustration} alt={type} width={30} className={cn.cardIillustration} />
            <div className={cn.cardAction}>
                <Chip content={t(`${type}.description`)} />
                <div className={cn.cardCTA}>
                    <div className={cn.container}>
                        {env.certificates.availableLocales.map(locale => (
                            <DownloadLink locale={locale} isAvailable={isAvailable} type={type} trackingKey={certificate.trackingKey} {...props} />
                        ))}
                    </div>
                </div>
            </div>
        </div >
    );
});

type DownloadLinkProps = {
    locale: string;
    isAvailable: boolean;
    type: string;
    teamId: string;
    enrollmentId: string;
    isAsynchronous: boolean;
    trackingKey: string;
}
const DownloadLink = memo(({ locale, isAvailable, type, teamId, enrollmentId, isAsynchronous, trackingKey }: DownloadLinkProps) => {

    const t = useI18n('certificates');
    const doTrackEvent = useTrackEvent();

    const url = useDownloadCertificateUrl({ locale, type, teamId, enrollmentId, isAsynchronous });

    const onClick = useCallback(() => {
        // eslint-disable-next-line camelcase
        doTrackEvent(trackingKey, { export_locale: locale });
    }, [doTrackEvent, trackingKey, locale]);

    return (<div className={cn.link}>
        <img src={IMAGE_SRCS[locale]} alt={t('imgAlt', { type, locale })} />
        {!isAvailable && <span className={cx(cn.text, cn.disabled)}>{t(`cta_${locale}`)}</span>}
        {isAvailable && (
            <Link
                to={url}
                onClick={onClick}
                target="_blank"
                className={cx(cn.text, cn.enabled)}
                rel="noopener noreferrer"
            >{t(`cta_${locale}`)}
            </Link>
        )}
    </div>);
});

export default memo(Certificates);
