import { redirectToLogin } from '_auth';
import { log } from '_console';
import { isApiError } from '_api';
import { setUser } from '_trackers';

import { useHttpGet } from './api';
import { getProfile } from '../../profile/endpoint';

/**
 * Hook to retrieve the signed-in user
 * This hook is called in the app overall wrapper, and set never to be staled, so in the rest of
 * the code, it can be assumed to be loaded.
 *
 * @returns The signed-in user along with request information
 */
export const useSignedInUser = () => {
    const req = useHttpGet(getProfile, {
        staleTime: Infinity,
        onError: (error: unknown) => {
            // We don't log if servers returns a 5xx error. And 401 is already handled by global api hook
            if (isApiError(error) && (error.status >= 500 || error.status === 401)) return;

            log('[Auth] 4xx error, redirect to login screen');
            redirectToLogin();
        },
        onSuccess: user => {
            setUser(user);
        }
    });

    const { data, ...rest } = req;
    return { ...rest, user: data };
};

export const useIsStaff = () => {
    const { user } = useSignedInUser();
    return user?.isStaff ?? false;
};
