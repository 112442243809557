import admissionCertificate from '../assets/illustrations/admission_certificate.png';
import firstDayCertificate from '../assets/illustrations/first_day_certificate.png';
import exitCertificate from '../assets/illustrations/exit_certificate.png';

export const AVAILABLE_CERTIFICATES = {
    ADMISSION: {
        type: 'admission_certificate',
        illustration: admissionCertificate,
        trackingKey: 'attestationDownloaded_admission'
    },
    FIRST_DAY: {
        type: 'first_day_certificate',
        illustration: firstDayCertificate,
        trackingKey: 'attestationDownloaded_starting'
    },
    EXIT: {
        type: 'exit_certificate',
        illustration: exitCertificate,
        trackingKey: 'attestationDownloaded_ending'
    }
};
