import type { Mapper } from '_api';

import type { User } from './types';
import { isStudentByRoles } from '_config';

const mapper: Mapper<User> = {
    from: user => ({
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        fullname: `${user.firstname} ${user.lastname}`,
        email: user.email,
        isMasquerading: user.masquerading,
        isStaff: !isStudentByRoles(user.roles),
        isValidated: user.has_validated_email
    })
};

export default mapper;
