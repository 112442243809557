import { warn } from '_console';
import { getUsableLocale } from './config';
import { getEnv } from '_config';

export const getTranslation = (translations: { [key: string]: string }, locale: string) => {
    const usableLocale = getUsableLocale(locale);
    if (!translations[usableLocale]) {
        warn(`Translation not found for locale ${usableLocale}`);
        return '';
    }
    return translations[usableLocale];
};

export const getTranslatable = (translatable: { [key: string]: string }, locale: string, fallbackLocale = 'en') => {
    if (typeof translatable !== 'object') {
        warn('It seems that your first parameter is not an translatable object');
        return '[No translation available in given translatable]';
    }
    const { availableLocales, defaultLocale } = getEnv();
    if (availableLocales?.includes(locale) && locale in translatable) return translatable[locale];
    return translatable[fallbackLocale] ?? translatable[defaultLocale] ?? Object.values(translatable)[0];
};
