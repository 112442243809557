import { memo } from 'react';

import { SimpleMarkdown } from '_ui';

import { getEnv } from '../global/config/env';
import { useI18n } from '../global/hooks/i18n';

import cn from './legals.module.scss';

type LegalsProps = {
    id: string;
}

const Legals = ({ id }: LegalsProps) => {
    const { legals } = getEnv();

    const t = useI18n(`legals.${legals?.key ?? 'legals'}.${id}`);

    return (
        <div className={cn.content}>
            <SimpleMarkdown value={t('content')} />
        </div>
    );
};

export default memo(Legals);
