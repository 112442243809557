import { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { getEnv } from './global/config/env';

const Head = () => {
    const env = getEnv();
    const { ascii, universe, isProd, schoolName } = env;

    const getUniverseAssetUrl = (asset: string) => `/${universe}/${asset}`;

    useEffect(() => {
        if (!ascii) return;
        /* ASCII ART */
        // eslint-disable-next-line
        console.log(`%c
        ${ascii.code}
`, `color: ${ascii.color}; font-family: Monospace`);
    }, [ascii]);

    return <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={getUniverseAssetUrl('apple-touch-icon.png')} />
        <link rel="icon" type="image/png" sizes="32x32" href={getUniverseAssetUrl('favicon-32x32.png')} />
        <link rel="icon" type="image/png" sizes="16x16" href={getUniverseAssetUrl('favicon-16x16.png')} />
        <link rel="icon" href={getUniverseAssetUrl('favicon.ico')} />
        <link rel="manifest" href={getUniverseAssetUrl(`${isProd ? 'web' : 'staging'}.webmanifest`)} />
        <meta name="description" content={`Dashboard by ${schoolName}`} />
        <title>Dashboard by {schoolName}</title>

    </Helmet>;
};

export default memo(Head);
