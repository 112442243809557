import { useCallback, useState, useEffect, useMemo } from 'react';

import type { Data, DictionaryGetter, LocalizeObject } from './config';
import { getTranslation } from './config';

type LocaleGetter = () => string

export * from './utils';
export { getDefaultLanguage } from './config';

export const makeUseI18n = (getLocale: LocaleGetter, getDictionary: DictionaryGetter) => (path: string) => {

    const locale = getLocale();
    const [dict, setDict] = useState<LocalizeObject>();

    const [folder, key] = useMemo(() => {
        const [folder, ...rest] = path.split('.');
        return [folder, rest.join('.')];
    }, [path]);

    useEffect(() => {
        // Load dictionary
        (async () => {
            const dict = await getDictionary(locale, folder);
            setDict(dict);
        })();
    }, [folder, locale]);

    const get = useCallback((path: string, data: Data = {}) => {
        if (!dict) return '';
        return getTranslation(dict, key ? `${key}.${path}` : path, data);
    }, [dict, key]);

    return get;
};

