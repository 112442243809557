import type { ReadEndpoint } from '_api';

import type { User } from './types';
import mapper from './mappers';

export const getProfile: ReadEndpoint<User> = {
    key: ['profile'],
    method: 'GET',
    path: '/api/v3/profile',
    formatResponse: mapper.from,
    simulator: { filepath: 'profile.simulateUserData' }
};
