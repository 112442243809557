import type { Mapper } from '_api';
import { stripProtocolAndPort } from '_utils/url';

import { getEnv } from '../global/config/env';
import type { StopMasqueradeResponse } from './types';

const stopMasquerade: Mapper<StopMasqueradeResponse> = {
    to: () => ({
        domain: stripProtocolAndPort(getEnv().adminUrl)
    }),
    from: data => ({
        token: data?.refresh_token
    })
};

export default stopMasquerade;
