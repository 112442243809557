import type { ReadEndpoint } from '_api';

import mapper from './mappers';
import type { TrainingWithCertificates } from './types';

export const getTrainingWithCertificates: ReadEndpoint<TrainingWithCertificates[]> = {
    key: ['trainingWithCertificates'],
    method: 'GET',
    path: '/api/v3/certificates',
    formatResponse: mapper.from,
    simulator: { filepath: 'certificates.certificates' }
};
