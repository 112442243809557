import { useContext } from 'react';

import { SignedInUserContext } from '../providers/SignedInUserProvider';
import { getEnv } from '../config/env';

export const useToken = () => {
    const { token } = useContext(SignedInUserContext);
    return token;
};

export const useTokenApiEndpointWithParams = (endpoint: string, params: URLSearchParams) => {
    const token = useToken();
    const env = getEnv();

    const baseUrl = `${env.baseApi}/${endpoint}?token=${token}`;
    const _params = new URLSearchParams(params).toString();

    return `${baseUrl}&${_params}`;
};
