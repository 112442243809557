import type { PropsWithChildren } from 'react';
import { createContext, memo, useState } from 'react';
import { createBrowserHistory } from 'history';

import { getDefaultLanguage } from '_i18n';
import { useUserAuth } from '_auth';

import { useSignedInUser } from '../hooks/user';

type UserContext = {
    token: string;
    storeToken: (token: string) => void;
    locale: string;
    storeLocale: (locale: string) => void;
}

const history = createBrowserHistory();
const cleanTokenInUrl = () => {
    history.replace({ search: '' });
};

export const SignedInUserContext = createContext<UserContext>({
    token: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    storeToken: () => { },
    locale: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    storeLocale: () => { }
});

const UserProvider = memo(({ children }: PropsWithChildren) => {
    // TODO change locale to user locale here ?
    const { isLoading } = useSignedInUser();
    if (isLoading) return null;
    return children;
});

const SignedInUserProvider = ({ children }: PropsWithChildren) => {

    const [token, storeToken] = useState('');
    const [locale, storeLocale] = useState(getDefaultLanguage());
    useUserAuth({
        cleanTokenInUrl,
        storeToken
    });

    return <SignedInUserContext.Provider value={{ token, storeToken, locale, storeLocale }}>
        {token !== '' && <UserProvider>{children}</UserProvider>}
    </SignedInUserContext.Provider>;
};

export default memo(SignedInUserProvider);
