import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { init as initTrackers } from '_trackers';

import { loadEnv } from './global/config/env';

import SignedInUserProvider from './global/providers/SignedInUserProvider';
import App from './App';
import Head from './Head';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

const init = async () => {
    await loadEnv();
    initTrackers({ appName: 'Dashboard' });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <Head />
            <QueryClientProvider client={queryClient}>
                <CssBaseline />
                <SignedInUserProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SignedInUserProvider>
            </QueryClientProvider>
        </React.StrictMode>,
    );
};

init();

