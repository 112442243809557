import type { ReadEndpoint, MutateEndpoint } from '_api';

import type { Enrollment } from './types';
import mapper from './mappers';

export const getEnrollments: ReadEndpoint<Enrollment[]> = {
    key: ['enrollments'],
    method: 'GET',
    path: '/api/v3/dashboard',
    formatResponse: mapper.from,
    simulator: { filepath: 'enrollments.simulateEnrollments' }
};

export const switchEnrollment: MutateEndpoint = {
    key: ['switchEnrollment'],
    method: 'POST',
    path: '/api/v3/enrollments/switch',
    formatParams: mapper.to,
    simulator: { filepath: 'enrollments.switchEnrollment' }
};
