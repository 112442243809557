import { memo, useCallback } from 'react';
import { useToggle } from '_utils/hooks';

import { ConfirmBottomBanner } from '_ui';

import { useSendConfirmationLink } from './hook';
import { useTrackEvent } from '../tracking/hooks';
import { useI18n } from '../global/hooks/i18n';
import { TRACKING } from './config';

import type { User } from '../profile/types';

type BottomBannerWrapperProps = {
    user: User;
}

const BottomBannerWrapper = ({ user }: BottomBannerWrapperProps) => {

    const t = useI18n('confirmationBottomBanner');
    const [isOpen, open, close] = useToggle(false);
    const doTrackEvent = useTrackEvent();

    const { mutateAsync: sendConfirmationLink } = useSendConfirmationLink();

    const sendConfirmationLinkEmail = useCallback(async () => {
        if (!user.isValidated) {
            //@ts-expect-error TOFIX _api does not allow no params here but it should
            await sendConfirmationLink();
        }
    }, [sendConfirmationLink, user]);

    const _onClick = useCallback(() => {
        doTrackEvent(TRACKING.VALIDATE_FROM_BANNER);
        sendConfirmationLinkEmail();
        open();
    }, [doTrackEvent, open, sendConfirmationLinkEmail]);

    return (<ConfirmBottomBanner
        t={t}
        email={user.email}
        open={isOpen}
        onClose={close}
        onClick={_onClick}
    />);
};

export default memo(BottomBannerWrapper);
