import { deleteJWTCookie } from '_auth';

import { useHttpMutate } from '../global/hooks/api';
import { getEnv } from '../global/config/env';
import { stopMasquerade } from './endpoint';
import type { StopMasqueradeResponse } from './types';

const masqueradeReferer = new URLSearchParams(window.location.search).get('masquerade-referer');

export const useStopMasquerade = () => {
    const env = getEnv();

    const rq = useHttpMutate(stopMasquerade, {
        onSuccess: async ({ token }: StopMasqueradeResponse) => {
            await deleteJWTCookie();
            window.location.href = `${masqueradeReferer ?? env.adminUrl}?token=${token}`;
        }
    });

    return rq.mutate;
};
