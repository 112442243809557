import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pageView, event as trackEvent } from '_trackers';

import { useSignedInUser } from '../global/hooks/user';

export const useTrackEvent = () => {
    const { user } = useSignedInUser();
    const userId = user?.id;

    const doTrackEvent = useCallback((action: string, eventData = {}) => {
        if (!userId) return;
        trackEvent({
            userId,
            event: action,
            data: eventData,
        });
    }, [userId]);

    return doTrackEvent;
};

export const usePageTracker = () => {

    const { pathname } = useLocation();
    const { user } = useSignedInUser();

    useEffect(() => {
        if (user) pageView(user.id, { pathname });
    }, [user, pathname]);
};
