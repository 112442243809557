import { error as logError } from '_console';

import { getEnv } from '../global/config/env';
import { useSignedInUser } from '../global/hooks/user';
import { useHttpGet, useHttpMutate } from '../global/hooks/api';
import { getEnrollments, switchEnrollment } from './endpoint';

/**
 * Hook to retrieve the signed-in user enrollments
 *
 * @returns The signed-in user enrollments
 */
export const useEnrollments = () => {
    const { adminUrl } = getEnv();
    const { user } = useSignedInUser();

    const req = useHttpGet(getEnrollments, {
        onError: (error: unknown) => {
            logError(error);
        },
        onSuccess: (data => {
            if (user?.isStaff && data.length === 0) {
                // staff user with no enrollments are redirected to admin
                window.location.replace(adminUrl);
            }
        })
    });

    const { data, ...rest } = req;
    return { ...rest, enrollments: data };
};

export const useSwitchEnrollment = () => useHttpMutate(switchEnrollment);
