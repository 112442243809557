import type { MutateEndpoint } from '_api';

import mapper from './mappers';

export const stopMasquerade: MutateEndpoint = {
    key: ['stopMasquerade'],
    method: 'POST',
    path: '/api/v3/masquerades/done',
    formatParams: mapper.to,
    formatResponse: mapper.from
};
