import { makeHttpGet, makeHttpMutate, isApiError } from '_api';
import { redirectToLogin } from '_auth';
import { useToken } from './token';

const getSimulator = async (path: string) => {
    if (!path) return null;
    const [folder, fn] = path.split('.');
    let module;
    try {
        const simulatorParam = new URLSearchParams(window.location.search).get('simulator');
        module = await import(`../../${folder}/simulators/${simulatorParam}.ts`);

    } catch {
        module = await import(`../../${folder}/simulators.ts`);
    }
    return module[fn];
};

export const useHttpGet = makeHttpGet(useToken, getSimulator);

export const useHttpMutate = makeHttpMutate(useToken, getSimulator);

export const apiErrorHandler = (error: unknown) => {
    // We don't log if servers returns a 5xx error. And 401 is already handled by global api hook
    if (isApiError(error) && (error.status >= 500 || error.status === 401)) return;

    // log('[Auth] 4xx error, redirect to login screen');
    redirectToLogin();
};
