/* eslint-disable @typescript-eslint/no-explicit-any */

import { startOfDay, endOfDay, parseISO } from 'date-fns';
import _groupBy from 'lodash.groupby';

import type { Mapper } from '_api';
import type { TrainingWithCertificates } from './types';

const mapCertificate = {
    from: ({ enrollment_id, team_id, training_name, is_asynchronous, start_date, end_date, type, is_available }: any) => ({
        enrollmentId: String(enrollment_id),
        teamId: String(team_id),
        name: training_name,
        startsOn: startOfDay(parseISO(start_date)),
        endsOn: endOfDay(parseISO(end_date)),
        type,
        isAvailable: is_available,
        isAsynchronous: is_asynchronous,
        uniqueId: `${enrollment_id}_${is_asynchronous}`
    })
};

const mapCertificateToTrainingWithCertificates = (certificates: any) => {
    const mapped = certificates.map((c: any) => mapCertificate.from(c));
    const certifs = _groupBy(mapped, 'uniqueId');

    return Object.values(certifs).map(c => {
        return c.reduce((acc, cur) => ({
            enrollmentId: cur.enrollmentId,
            teamId: cur.teamId,
            name: cur.name,
            startsOn: cur.startsOn,
            endsOn: cur.endsOn,
            isAsynchronous: cur.isAsynchronous,
            uniqueId: cur.uniqueId,
            certificates: [
                ...(acc.certificates || []),
                { type: cur.type, isAvailable: cur.isAvailable }
            ]
        }), {});
    });
};

const mapper: Mapper<TrainingWithCertificates[]> = {
    from: ({ async_certificates, bootcamp_certificates }) => {
        return mapCertificateToTrainingWithCertificates([...async_certificates, ...bootcamp_certificates]);
    }
};

export default mapper;
