import { memo, useCallback, useState } from 'react';
import { cx } from '@emotion/css';
import { GuyFawkesMask as MaskIcon, Close as CloseIcon } from 'mdi-material-ui';
import { ButtonBase, IconButton } from '@mui/material';

import { SimpleMarkdown } from '_ui';

import { useI18n } from '../global/hooks/i18n';
import { useStopMasquerade } from './hooks';

import cn from '_ui/scss/headband.module.scss';
import bn from '_ui/scss/submit.module.scss';
import type { User } from '../profile/types';

type MasqueradeHeadbandProps = {
    user: User;
}

const MasqueradeHeadband = ({ user }: MasqueradeHeadbandProps) => {
    const [hidePanel, setHidePanel] = useState(false);
    const t = useI18n('masqueradeHeadband');
    const { fullname } = user;

    const onStopMasquerade = useStopMasquerade();

    const _onClick = useCallback(() => {
        onStopMasquerade(null);
    }, [onStopMasquerade]);

    const _onClose = useCallback(() => {
        setHidePanel(true);
    }, []);

    if (hidePanel) return null;

    return (
        <div className={cx(cn.headband)}>
            <IconButton size="small" className={cn.closeButton} onClick={_onClose}>
                <CloseIcon fontSize="inherit" />
            </IconButton>
            <span className={cx(cn.text)}><MaskIcon /><SimpleMarkdown value={`${t('content', { fullname })}`} /></span>
            <ButtonBase className={bn.submit} onClick={_onClick}>{t('stop')}</ButtonBase>
        </div>
    );
};

export default memo(MasqueradeHeadband);
