import { memo } from 'react';

import { useAsyncCardInfos } from './hooks/async';
import { useBootcampCardInfos } from './hooks/bootcamp';

import type { AsyncEnrollment, BootcampEnrollment, Enrollment } from '../enrollments/types';
import { isAsync } from '../enrollments/utils';

import Card from './Card';

type CardWrapperProps = {
    enrollment: Enrollment;
}

type AsyncCardProps = {
    enrollment: AsyncEnrollment;
}

type BootcampCardProps = {
    enrollment: BootcampEnrollment;
}

const AsyncCard = memo(({ enrollment }: AsyncCardProps) => {

    const cardInfos = useAsyncCardInfos(enrollment);

    return <Card
        {...cardInfos}
        estimatedTime={enrollment.estimatedTime}
        startsOn={(!cardInfos.isAvailable && enrollment.startsOn) || undefined}
        progression={enrollment.progression}
    />;
});

const BootcampCard = memo(({ enrollment }: BootcampCardProps) => {

    const cardInfos = useBootcampCardInfos(enrollment);

    return <Card {...cardInfos} />;
});

const CardWrapper = ({ enrollment }: CardWrapperProps) => {
    if (isAsync(enrollment)) {
        return <AsyncCard enrollment={enrollment} />;
    }
    return <BootcampCard enrollment={enrollment} />;
};

export default memo(CardWrapper);
