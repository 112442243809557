/* eslint-disable @typescript-eslint/no-explicit-any */
import { startOfDay, parseISO } from 'date-fns';

import type { Mapper } from '_api';
import type { AsyncTrainingType, BootcampTrainingType } from '_config';
import { ASYNC_TRAINING_TYPES, ASYNC_TRAINING_TYPES_VALUES, BOOTCAMP_TRAINING_TYPES, BOOTCAMP_TRAINING_TYPES_VALUES } from '_config';

import type { AsyncEnrollment, AsyncEnrollments, BootcampEnrollment, BootcampEnrollments, Enrollment } from './types';

// track can be null in api, prevent error by displaying TRAINING by default
const DEFAULT_TRACK = { en: 'Training', fr: 'Formation', de: 'Bildung' };

const bootcampEnrollmentMapper: Mapper<BootcampEnrollment> = {
    from: enrollment => ({
        type: 'bootcamp',
        trainingType: enrollment.trainingType,
        isCurrent: enrollment.isCurrent,
        id: enrollment.id,
        name: enrollment.training.name_translations,
        track: enrollment.training.track?.name_translations ?? DEFAULT_TRACK,
    })
};

const calcProgressionPercentage = (total: number, completed: number) =>
    total === 0 ? 0 : Math.min(100, Math.round((completed / total) * 100));

const asyncEnrollmentMapper: Mapper<AsyncEnrollment> = {
    from: enrollment => ({
        type: 'async',
        trainingType: enrollment.trainingType,
        id: enrollment.id,
        name: enrollment.program.program_chain.name,
        track: enrollment.program.track?.name_translations ?? DEFAULT_TRACK,
        programId: enrollment.program.id,
        estimatedTime: enrollment.program.duration,
        progression: calcProgressionPercentage(enrollment.total_milestones, enrollment.completed_milestones),
        startsOn: startOfDay(parseISO(enrollment.starts_on)),
        isCompleted: enrollment.completed_milestones > 0 && enrollment.completed_milestones >= enrollment.total_milestones,
        isStarted: enrollment.has_started
    })
};

const getBootcampEnrollmentTrainingType = (enrollment: any): BootcampTrainingType => {
    const trainingType = enrollment?.training?.course_type;
    // prevent error for not handled yet training type by setting bootcamp as default
    return BOOTCAMP_TRAINING_TYPES_VALUES.includes(trainingType) ? trainingType : BOOTCAMP_TRAINING_TYPES.SKILL_COURSE;
};

const bootcampEnrollmentsMapper: Mapper<BootcampEnrollments> = {
    from: ({ enrollments, currentEnrollmentId }) => {
        return (enrollments || []).reduce(
            (acc: BootcampEnrollments, enrollment: any) => {
                const trainingType = getBootcampEnrollmentTrainingType(enrollment);
                return {
                    ...acc,
                    [trainingType]: [...acc[trainingType], bootcampEnrollmentMapper.from({ ...enrollment, trainingType, isCurrent: enrollment.id === currentEnrollmentId })]
                };
            },
            { [BOOTCAMP_TRAINING_TYPES.BOOTCAMP]: [], [BOOTCAMP_TRAINING_TYPES.WORK_AND_STUDY]: [] }
        );
    }
};

const getAsyncEnrollmentTrainingType = (enrollment: any): AsyncTrainingType => {
    const trainingType = enrollment?.program?.program_chain?.program_type;
    // prevent error for not handled yet training type by setting skill_course as default
    return ASYNC_TRAINING_TYPES_VALUES.includes(trainingType) ? trainingType : ASYNC_TRAINING_TYPES.SKILL_COURSE;
};

const asyncEnrollmentsMapper: Mapper<AsyncEnrollments> = {
    from: ({ enrollments }) => (enrollments || []).reduce(
        (acc: AsyncEnrollments, enrollment: any) => {
            const trainingType = getAsyncEnrollmentTrainingType(enrollment);
            return {
                ...acc,
                [trainingType]: [...acc[trainingType], asyncEnrollmentMapper.from({ ...enrollment, trainingType })]
            };
        },
        { [ASYNC_TRAINING_TYPES.DISCOVERY_COURSE]: [], [ASYNC_TRAINING_TYPES.SKILL_COURSE]: [] }
    )
};

/**
 * Map and sort enrollments by training types
 * (discovery, bootcamps, wns, skill)
 */
const mapper: Mapper<Enrollment[]> = {
    from: ({ async_enrollments, enrollments, current_enrollment_id }) => {
        const bootcampEnrollments = bootcampEnrollmentsMapper.from({ enrollments, currentEnrollmentId: current_enrollment_id });
        const asyncEnrollments = asyncEnrollmentsMapper.from({ enrollments: async_enrollments });
        return [
            ...asyncEnrollments[ASYNC_TRAINING_TYPES.DISCOVERY_COURSE],
            ...bootcampEnrollments[BOOTCAMP_TRAINING_TYPES.BOOTCAMP],
            ...bootcampEnrollments[BOOTCAMP_TRAINING_TYPES.WORK_AND_STUDY],
            ...asyncEnrollments[ASYNC_TRAINING_TYPES.SKILL_COURSE]
        ];
    },
    to: id => ({ enrollment_id: id })
};

export default mapper;
